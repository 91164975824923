import { BorderStyle, Document, Packer, Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType, TextRun, AlignmentType, Header, ImageRun } from "docx";
import { saveAs } from "file-saver";
import { useState } from "react";
import { ICategory, IProject, ITask } from "../../models";
import { MonthNumber } from "../../constants/MonthNumber";

export const TimesheetDocsTemplate = ({ image, chosenTimesheet, chosenProject, ...props }: any) => {

    const chosenProjectId = chosenProject?.id || chosenProject?.value
    const chosenProjectName = chosenProject?.name || chosenProject?.label

    let tasks = props.tasks.map((item: any) => ({
        ...item,
        hours: +(item?.hours).toFixed(2),
      }));

    tasks = chosenProjectId ? tasks?.filter((item: ITask) => item.project.id === chosenProjectId).length ? tasks?.filter((item: ITask) => item.project.id === chosenProjectId) : tasks : tasks
    tasks = tasks.reverse()


    const tasksList = tasks.map((item: ITask, index: number) => {
        const isLastItem = tasks.length - 1 === index

        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item.date,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item?.project?.name,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item.work_place[0].toUpperCase() + item.work_place.slice(1),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: (item.hours / 8).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item.category.name,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.CENTER
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: item.task,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.LEFT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                })
            ]
        })
    })

    const allProjectsWithoutDoubles = tasks.reduce((arr: IProject[], curr: ITask) => {

        if (!arr.some(item => item.id === curr.project.id)) {
            arr.push(curr.project)
        }

        return arr;
    }, [])

    let countOfAllProjects = 0

    const projectsTotal = allProjectsWithoutDoubles.map((project: IProject, index: number) => {

        const sum = tasks.filter((item: ITask) => item.project.id === project.id).reduce((arr: number, curr: ITask) => {
            return arr += curr.hours
        }, 0)

        countOfAllProjects += sum

        const isLastItem = allProjectsWithoutDoubles.length - 1 === index

        return new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: project.name,
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                }),
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: (sum / 8).toFixed(2).toString(),
                                font: "Calibri",
                            })
                        ],
                        alignment: AlignmentType.RIGHT
                    })],
                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: isLastItem ? BorderStyle.SINGLE : BorderStyle.DOTTED, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                    verticalAlign: VerticalAlign.CENTER,
                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                })
            ]
        })

    })

    const amountHomeTimes = tasks.reduce((sum: number, curr: ITask) => {
        return sum = sum + (curr.work_place === "home" ? curr.hours : 0)
    }, 0)
    const amountFieldTimes = tasks.reduce((sum: number, curr: ITask) => {
        return sum = sum + (curr.work_place === "field" ? curr.hours : 0)
    }, 0)






    const tableLogo = {
        default: new Header({
            children: [
                new Paragraph({
                    children: [
                        new ImageRun({
                            data: image,
                            transformation: {
                                width: 70,
                                height: 55,
                            },
                        }),
                    ],
                    alignment: AlignmentType.RIGHT,
                }),
            ],
        }),
    }

    const isHaveChosenProject = chosenProjectId ? [
        new Paragraph({
            children: [
                new TextRun({ text: "Project number: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: chosenProjectName, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ] : []

    const tableHeader = [
        // Заголовок
        new Paragraph({
            children: [new TextRun({ text: "Activity Timesheet", size: 40, bold: true, font: 'Calibri' })],
            alignment: AlignmentType.LEFT,
        }),
        new Paragraph(""),
        new Paragraph(""),
        // Expert Name
        new Paragraph({
            children: [
                new TextRun({ text: "Expert Name: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${chosenTimesheet.user.first_name} ${chosenTimesheet.user.last_name}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
        // Project number
        ...isHaveChosenProject,
        // Period
        new Paragraph({
            children: [
                new TextRun({ text: "Period: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${MonthNumber()[+chosenTimesheet.date.split('/')[1]]?.en_title} 20${chosenTimesheet.date.split('/')[2]}`, italics: true, size: 28, font: 'Calibri' })
            ]
        }),
    ]

    const mainTable = [
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Date",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 10, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Project number",  // Перенос текста
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER  // Горизонтальное выравнивание
                            })],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 3 },
                                bottom: { style: BorderStyle.SINGLE, size: 3 },
                                left: { style: BorderStyle.SINGLE, size: 3 },
                                right: { style: BorderStyle.SINGLE, size: 3 }
                            },
                            verticalAlign: VerticalAlign.CENTER,  // Вертикальное выравнивание
                            width: { size: 12, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Home/Field",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 6, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Working days",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 12, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Task",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 13, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: "Activity description",
                                        bold: true,
                                        font: "Calibri",
                                    })
                                ],
                                alignment: AlignmentType.CENTER
                            })],
                            borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                            verticalAlign: VerticalAlign.CENTER,
                            width: { size: 40, type: WidthType.PERCENTAGE },
                            shading: { fill: "FBC900" },
                            margins: { top: 30, bottom: 30, left: 50, right: 50 },
                        })
                    ]
                }),

                ...tasksList
            ]
        }),
    ]

    const doubleTables = [
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Project",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 12, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Working Days",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 12, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        }),

                                        ...projectsTotal,

                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Total:",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    shading: { fill: "EFEFEF" },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: (countOfAllProjects / 8).toFixed(2).toString(),
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    shading: { fill: "EFEFEF" },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ],
                            width: { size: 12, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "..",
                                            color: "FFFFFF"
                                        })
                                    ]
                                })
                            ],
                            width: { size: 4, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Home/Field",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 12, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Working Days",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.CENTER
                                                    })],
                                                    width: { size: 12, type: WidthType.PERCENTAGE },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 3 }, bottom: { style: BorderStyle.SINGLE, size: 3 }, left: { style: BorderStyle.SINGLE, size: 3 }, right: { style: BorderStyle.SINGLE, size: 3 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: { fill: "FBC900" },
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Home",
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: (amountHomeTimes / 8).toFixed(2).toString(),
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Field",
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.DOTTED, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: (amountFieldTimes / 8).toFixed(2).toString(),
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.DOTTED, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: "Total:",
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    shading: { fill: "EFEFEF" },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: ((amountHomeTimes + amountFieldTimes) / 8).toFixed(2).toString(),
                                                                bold: true,
                                                                font: "Calibri",
                                                            })
                                                        ],
                                                        alignment: AlignmentType.RIGHT
                                                    })],
                                                    shading: { fill: "EFEFEF" },
                                                    borders: { top: { style: BorderStyle.SINGLE, size: 1 }, bottom: { style: BorderStyle.SINGLE, size: 1 }, left: { style: BorderStyle.SINGLE, size: 1 }, right: { style: BorderStyle.SINGLE, size: 1 } },
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    margins: { top: 30, bottom: 30, left: 50, right: 50 },
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ],
                            width: { size: 12, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: "..",
                                            color: "FFFFFF"
                                        })
                                    ]
                                })
                            ],
                            width: { size: 4, type: WidthType.PERCENTAGE },
                            borders: { top: { style: BorderStyle.NONE, color: "FFFFFF" }, bottom: { style: BorderStyle.NONE, color: "FFFFFF" }, left: { style: BorderStyle.NONE, color: "FFFFFF" }, right: { style: BorderStyle.NONE, color: "FFFFFF" } }
                        }),
                    ],
                })
            ]
        }),
    ]

    const approvalUser = chosenTimesheet.approves.map((approval: any) => {
        return new Paragraph({
            children: [
                new TextRun({ text: "Approved by: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: `${approval.user.first_name} ${approval.user.last_name}`, italics: true, size: 28, font: 'Calibri' })
            ]
        })
    })

    const bottomApproval = chosenTimesheet?.status === "approve" ? [
        ...approvalUser,

        new Paragraph({
            children: [
                new TextRun({ text: "Date: ", bold: true, size: 28, font: 'Calibri' }),
                new TextRun({ text: chosenTimesheet?.updated_at, italics: true, size: 28, font: 'Calibri' })
            ]
        })
    ] : []

    const doc = new Document({
        sections: [
            {
                headers: tableLogo,
                children: [
                    ...tableHeader,

                    new Paragraph(""),
                    new Paragraph(""),

                    ...mainTable,

                    new Paragraph(""),
                    new Paragraph({ children: [new TextRun({ text: "Summary", bold: true, size: 36, font: 'Calibri' })] }),
                    new Paragraph(""),

                    ...doubleTables,

                    new Paragraph(""),
                    new Paragraph(""),

                    ...bottomApproval
                ]
            }
        ]
    });

    const today = `${String(new Date().getFullYear()).slice(2, 4)}${(new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}${new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()}`

    Packer.toBlob(doc).then((blob: any) => {
        // saveAs(blob, "Overall_Word.docx");
        saveAs(blob, `${today}_Timesheet_${chosenTimesheet?.user?.first_name}_${chosenTimesheet?.user?.last_name}_${MonthNumber()[+(chosenTimesheet?.date[3] + chosenTimesheet?.date[4])]?.en_title}`);
    });

};
