import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ICurrency,
  IExpense,
  IProject,
  ISelect,
  IStatistic,
  ITask,
  ITimesheet,
  IUser,
  IUserStatistic,
} from "../../../models";
import { MonthNumber } from "../../../constants/MonthNumber";
import axios, { AxiosResponse } from "axios";
import { getApiLink } from "../../../functions/getApiLink";
import { PopupContext } from "../../../App";
import { getBearer } from "../../../functions/getBearer";
import {
  setChosenTimesheet,
  setExpenses,
  setTasks,
} from "../../../storage/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { mergeAndSum } from "../../../functions/mergeAndSumStatistic";
import { Translate } from "../../../components/translate/Translate";
import { useTranslation } from "react-i18next";
import { currency } from "../../../constants/Currency";
import { SummaryExportTable } from "./SummaryExportTable";
import { GetAccessToken } from "../../../api/GetAccessToken";
import { SummaryExportTableTimesheet } from "./SummaryExportTableTimesheet";
import { SetTimesheet } from "../../../api/SetTimesheet";
import { SummaryExcel } from "./SummaryExcel";
import { SummaryAllProjects } from "../../../components/summaryAllProjects/SummaryAllProjects";
import { toast } from "react-toastify";
import HTMLReactParser from "html-react-parser";
import { useClickOutside } from "../../../hooks/ClickOutside";
import { GetCurrency } from "../../../api/GetCurrency";

interface ISummaryItemProps {
  dataItem: ITimesheet;
  isOpen: boolean;
}

export const SummaryItem: React.FC<ISummaryItemProps> = ({
  dataItem,
  isOpen,
}) => {
  const [statisticList, setStatisticList] = useState<IUserStatistic[]>([]);
  const [isActive, setIsActive] = useState(isOpen);
  const [statistic, setStatistic] = useState<IStatistic | undefined>();

  const userData: IUser = useSelector((state: any) => state.toolkit.user);

  useEffect(() => {
    setTimeout(() => {
      setIsActive(isOpen);
    }, 100);
  }, []);

  const currencyList: ICurrency = useSelector(
    (state: any) => state.toolkit.currency
  );

  const setPopup: any = useContext(PopupContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const itemDate = dataItem.updated_at;
  const dateForStatus = itemDate.replaceAll(".", " / ");

  const { t } = useTranslation();

  const timesheetStatus: any = {
    progress: {
      title: <Translate>summary_page.main.in_progress</Translate>,
      icon: "#attention",
    },
    waiting: {
      title: `${t("summary_page.main.sent_for_approval")} ${dateForStatus}`,
      icon: "#time",
    },
    reject: {
      title: `${dateForStatus}`,
      icon: "#round-error",
    },
    approve: {
      title: `${dateForStatus}`,
      icon: "#round-check",
    },
  };

  const handleEntryCost = () => {
    dispatch(setChosenTimesheet(dataItem));

    if (!dataItem?.id) return;

    getBearer("get");
    axios
      .get(getApiLink(`/api/timesheet/expenses/?timesheet_id=${dataItem.id}`))
      .then(({ data }) => {
        navigate("/costs");
        dispatch(setExpenses(data));
      })
      .catch((er) => {
        er?.response?.status === 401 &&
          GetAccessToken(dispatch, handleEntryCost);
      });
  };

  const handleEntryTask = () => {
    dispatch(setChosenTimesheet(dataItem));

    getBearer("get");
    axios
      .get(getApiLink(`/api/timesheet/tasks/?timesheet_id=${dataItem.id}`))
      .then(({ data }) => {
        navigate("/");
        dispatch(setTasks(data));
      });
  };

  useEffect(() => {
    setStatisticList(
      mergeAndSum(statistic?.expenses, statistic?.tasks).statistic
    );
  }, [statistic]);

  useEffect(() => {
    if (!dataItem?.id) return;

    getBearer("get");
    axios
      .get(getApiLink(`/api/timesheet/statistics/?timesheet_id=${dataItem.id}`))
      .then(({ data }) => {
        setStatistic(data);
      })
      .catch((er) => {});
  }, []);

  const chosenTimesheet: ITimesheet = useSelector(
    (state: any) => state.toolkit.chosenTimesheet
  );

  const [isClickToExport, setIsClickToExport] = useState(false);
  const handleExportPdf = () => {
    getBearer("get");

    axios
      .get(
        getApiLink(
          `/api/base/currency/rate/?date=${
            dataItem.date.split("/")[1] ?? new Date().getMonth() + 1
          }.${`20${dataItem.date.split("/")[2]}` ?? new Date().getFullYear()}`
        )
      )
      .then((currency: AxiosResponse<ICurrency>) => {
        axios
          .get(getApiLink(`/api/timesheet/tasks/?timesheet_id=${dataItem.id}`))
          .then((resTasks: AxiosResponse<ITask[]>) => {
            getBearer("get");
            axios
              .get(
                getApiLink(
                  `/api/timesheet/expenses/?timesheet_id=${dataItem.id}`
                )
              )
              .then((resExpenses: AxiosResponse<IExpense[]>) => {
                const allTasks =
                  chosenProject?.value !== "default"
                    ? resTasks.data.filter(
                        (item) => item.project.id === chosenProject?.value
                      )
                    : resTasks.data;
                const allExpenses =
                  chosenProject?.value !== "default"
                    ? resExpenses.data.filter(
                        (item) => item.project.id === chosenProject?.value
                      )
                    : resExpenses.data;
                const allStatistics =
                  chosenProject?.value !== "default"
                    ? statisticList.filter(
                        (item) => item.project.id === chosenProject?.value
                      )
                    : statisticList;

                SummaryExcel({
                  chosenTimesheet: dataItem,
                  translate: t,
                  data: allStatistics,
                  tasks: allTasks,
                  expenses: allExpenses,
                  currency: currency.data,
                  chosenProject,
                });
              })
              .catch((er) => {
                er?.response?.status === 401 &&
                  GetAccessToken(dispatch, handleEntryCost);
              });
          })
          .catch((er) => {
            er?.response?.status === 401 &&
              GetAccessToken(dispatch, handleEntryCost);
          });
      })
      .catch((error) => {});
  };

  const handleCancelSending = () => {
    axios
      .post(
        getApiLink(
          `/api/timesheet/my/send/?timesheet_id=${dataItem.id}&status=progress`
        )
      )
      .then(({ data }) => {
        if (!data.status) return;

        SetTimesheet(dispatch);
      });
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    !isActive && setIsVisible(isActive);

    setTimeout(() => {
      setIsVisible(isActive);
    }, 500);
  }, [isActive]);

  const [chosenProject, setChosenProject] = useState<ISelect>();

  const leaderStatuses: { [key: string]: string } = {
    waiting:
      '<svg width="20" height="20" viewBox="0 0 20 20"><use xlink:href="#time"></use></svg>',
    approve:
      '<svg width="20" height="20" viewBox="0 0 20 20"><use xlinkHref="#round-check"></use></svg>',
    reject:
      '<svg width="20" height="20" viewBox="0 0 20 20"><use xlink:href="#round-error"></use></svg>',
  };

  const { rootEl } = useClickOutside(setIsActive);

  const [isApproveTimesheet, setIsApproveTimesheet] = useState(
    dataItem.approves.some((item) => item.status === "approve") ||
      dataItem.status === "approve"
  );

  const [isCanNotCancel, setIsCanNotCancel] = useState(true);

  const [allApprovals, setAllApprovals] = useState<any>([]);
  useEffect(() => {
    const newArray: any = [
      ...dataItem.approves,
      ...dataItem.user.managers,
    ].filter((item) => {
      if (
        !dataItem.approves.some((i: any) => i.user.id === item?.manager?.id)
      ) {
        return item;
      }
    });

    setIsCanNotCancel(
      newArray.some((i: any) => i.status === "approve")
    );

    setAllApprovals(newArray);

    if (userData.status === "manager") {
      // setAllApprovals([...newArray, { manager: userData }]);
    }
  }, [dataItem]);

  return (
    <>
      {isClickToExport && (
        <SummaryExportTable
          statistic={statistic}
          statisticList={statisticList}
        />
      )}
      {isClickToExport && <SummaryExportTableTimesheet />}

      <div ref={rootEl} className={`summary-item ${isActive && "is-active"}`}>
        <div
          onClick={(_) => setIsActive((prev) => !prev)}
          className="summary-item__target"
        >
          <h2 className="summary-item__target--name">
            {MonthNumber()[+dataItem.date.slice(3, 5)].translate}
          </h2>
          <div
            className={`summary-item__target--status ${
              dataItem.status === "waiting" && "is-waiting"
            } ${dataItem.status === "reject" && "is-danger"} ${
              dataItem.status === "approve" && "is-success"
            }`}
          >
            <span>{timesheetStatus[dataItem.status]?.title}</span>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <use xlinkHref={timesheetStatus[dataItem.status]?.icon}></use>
            </svg>
          </div>
          <button className="summary-item__target--toggle" type="button">
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              className="summary-item__target--arrow"
            >
              <use xlinkHref="#drop-down-arrow"></use>
            </svg>
          </button>
        </div>
        <div className="summary-item__block">
          <div style={{ overflow: isVisible ? "visible" : "hidden" }}>
            {!!allApprovals.length && (
              <div className="summary-item__elements-list summary-item__elements-list__leaders">
                <span></span>
                <strong>Approving Managers:</strong>
                <ul className="leaders">
                  {allApprovals.map((item: any, index: number) => (
                    <li
                      key={index}
                      className={`summary-item__target--status is-${
                        item?.status ?? "waiting"
                      }`}
                    >
                      <span>
                        {item?.user?.first_name ?? item?.manager?.first_name}{" "}
                        {item?.user?.last_name ?? item?.manager?.last_name}
                      </span>
                      {HTMLReactParser(
                        leaderStatuses[item?.status ?? "waiting"] ?? ""
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="summary-item__elements-list">
              {statisticList?.length
                ? statisticList
                    ?.filter((item) =>
                      chosenProject?.value === "default"
                        ? item
                        : item.project.id === chosenProject?.value
                    )
                    ?.map((item: any, index: number) => (
                      <div key={index} className="summary-item__element">
                        <h3 className="summary-item__element--name">
                          {item.project.name} – {item.project.description}
                        </h3>
                        <div className="summary-item__element--progress">
                          <span>
                            {item.task.hours}
                            <Translate>timesheet_page.table.h</Translate>
                          </span>
                          <span
                            data-value={`${
                              item.task.percent > 100 ? 100 : item.task.percent
                            }%`}
                          >
                            <div
                              className="line_done"
                              style={{ width: `${item.task.percent}%` }}
                            />
                          </span>
                          <span>
                            {(item.task.hours / 8).toFixed(1)}{" "}
                            <Translate>d</Translate>
                          </span>
                        </div>
                        <div className="summary-item__element--progress">
                          <span>
                            {item.expense.sum.toFixed(2)} {currency}
                          </span>
                          <span
                            data-value={`${
                              item.expense.percent > 100
                                ? 100
                                : item.expense.percent
                            }%`}
                          >
                            <div
                              className="line_done"
                              style={{ width: `${item.expense.percent}%` }}
                            />
                          </span>
                        </div>
                      </div>
                    ))
                : "No data yet"}
            </div>
            <div className="summary-item__total">
              <b className="summary-item__total--title">
                <Translate>summary_page.main.total</Translate>
              </b>
              <div className="summary-item__total--element summary-item__total-element summary-item__total-element__two">
                <div className="summary-item__total-element--icon">
                  <svg width="20" height="20" viewBox="0 0 13 13">
                    <use xlinkHref="#time"></use>
                  </svg>
                </div>
                <b className="summary-item__total-element--name">
                  <Translate>
                    summary_page.main.time_spent_for_projects
                  </Translate>
                </b>
                <button
                  onClick={handleEntryTask}
                  className="summary-item__total-element--link"
                >
                  <Translate>Timesheet</Translate>
                  <svg width="7" height="10" viewBox="0 0 7 10">
                    <use xlinkHref="#arrow-next"></use>
                  </svg>
                </button>
                <div className="summary-item__total-element--value">
                  {statistic?.all_hours.toFixed(1)}{" "}
                  <Translate>summary_page.main.hours</Translate>
                </div>
                <div className="summary-item__total-element--value summary-item__total-element--value-days">
                  {((statistic?.all_hours ?? 0) / 8).toFixed(1)}{" "}
                  <Translate>days</Translate>
                </div>
              </div>
              <div className="summary-item__total--element summary-item__total-element">
                <div className="summary-item__total-element--icon">
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <use xlinkHref="#money"></use>
                  </svg>
                </div>
                <b className="summary-item__total-element--name">
                  <Translate>
                    summary_page.main.money_spent_for_projects
                  </Translate>
                </b>
                <button
                  onClick={handleEntryCost}
                  className="summary-item__total-element--link"
                >
                  <Translate>Costs</Translate>
                  <svg width="7" height="10" viewBox="0 0 7 10">
                    <use xlinkHref="#arrow-next"></use>
                  </svg>
                </button>
                <div className="summary-item__total-element--value">
                  {statistic?.all_sum.toFixed(2)} {currency}
                </div>
              </div>
            </div>
            <div className="summary-item__footer">
              <div className="summary-item__footer--col">
                {dataItem.status === "waiting" && (
                  <div className="summary-item__message is-waiting">
                    <svg width="20" height="20" viewBox="0 0 13 13">
                      <use xlinkHref="#time"></use>
                    </svg>
                    <p>
                      <Translate>
                        summary_page.other.already_sent_for_approval
                      </Translate>
                    </p>
                  </div>
                )}

                {dataItem.status === "reject" && (
                  <div className="summary-item__message is-danger">
                    <svg width="20" height="20" viewBox="0 0 13 13">
                      <use xlinkHref="#round-error"></use>
                    </svg>
                    <p>
                      <Translate>
                        summary_page.other.timesheet_rejected
                      </Translate>
                    </p>
                  </div>
                )}

                {dataItem.status === "approve" && (
                  <div className="summary-item__message is-success">
                    <svg width="20" height="20" viewBox="0 0 13 13">
                      <use xlinkHref="#round-check"></use>
                    </svg>
                    <p>
                      <Translate>summary_page.other.summary_approved</Translate>
                    </p>
                  </div>
                )}
              </div>
              <div
                className="summary-item__footer--col"
                style={{
                  gridTemplateColumns: !isCanNotCancel
                    ? "164px 1fr 1fr"
                    : "1fr 1fr",
                }}
              >
                <SummaryAllProjects setValue={setChosenProject} />

                <button
                  className="summary-item__button btn is-grey is-transparent"
                  type="button"
                  onClick={handleExportPdf}
                >
                  <Translate>
                    summary_page.main.export_monthly_summary
                  </Translate>
                  <svg width="16" height="17" viewBox="0 0 16 17">
                    <use xlinkHref="#download"></use>
                  </svg>
                </button>

                {!isCanNotCancel && (
                  <>
                    {!(dataItem.status === "waiting" || isApproveTimesheet) ? (
                      <a
                        onClick={(_) =>
                          setPopup({
                            popup: "approve-timesheet-popup",
                            data: dataItem,
                          })
                        }
                        className={`summary-item__button btn open-popup`}
                        type="button"
                      >
                        <Translate>
                          summary_page.main.send_timesheet_for_approval
                        </Translate>
                      </a>
                    ) : (
                      !isApproveTimesheet && (
                        <a
                          onClick={handleCancelSending}
                          className={`summary-item__button btn is-grey`}
                          type="button"
                        >
                          <Translate>cancel_sending</Translate>
                        </a>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
